import React, { useState, useEffect } from 'react';
import workerImage from '../Assets/aboutusimage.jpg';
import visionandmissionImage from '../Assets/visionandmission.png';
import { motion } from 'framer-motion';

const AboutUs = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('aboutUs');
      if (element) {
        const { top } = element.getBoundingClientRect();
        setIsVisible(top < window.innerHeight * 0.75);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <motion.div
      id="aboutUs"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
      transition={{ duration: 0.5 }}
      className="px-4 md:px-10 lg:px-20"
    >
      <h1 className="text-6xl font-bold text-center mb-8">Who are We?</h1>

      <div className="flex flex-col-reverse md:flex-row justify-center items-center text-black p-4 md:p-20">
        <div className="text-left md:pr-16 mb-4 md:mb-0">
          <h1 className="text-4xl font-bold mb-4 text-center">About Rands Lanka</h1>
          <p className="text-lg text-justify">
            Welcome to Rands Lanka: Your premier destination for top-quality tissue products.
            <br /><br />
            At Rands Lanka, we prioritize quality in every aspect of our tissue products, ensuring they not only exceed industry benchmarks but also deliver unmatched comfort and hygiene. Our commitment to excellence guarantees that every product we offer provides the utmost comfort and hygiene experience.
            <br /><br />
            What sets us apart is our unwavering commitment to crafting high-quality products that are also affordable. Our dedication to excellence ensures top-notch performance in every item we offer, without compromising on value.
            <br /><br />
            Choosing Rands Lanka is a conscious decision to partner with a brand that prioritizes your satisfaction and well-being. We take pride in being your trusted partner, delivering premium products that not only enhance your daily life but also fit within your budget, all while upholding the highest standards of quality and comfort.
            </p>
        </div>
        <img src={workerImage} alt="Rands Lanka" className="rounded-3xl w-full md:w-1/2 h-auto" loading='lazy'/>
      </div>

      <div className="flex flex-col-reverse md:flex-row justify-center items-center text-black p-4 md:p-20 overflow">
        <img src={visionandmissionImage} alt="Left Image" className="w-full md:w-1/2 h-auto mb-4 md:mb-0" loading='lazy'/>
        <div className="text-right md:pl-16">
          <h1 className="text-4xl font-bold mb-4 text-center">Vision</h1>
          <p className="text-lg text-justify">
          Becoming Sri Lanka's most sought-after manufacturer for premium soft paper products.
          </p>
          <h1 className="text-4xl font-bold mb-4 text-center">Mission</h1>
          <p className="text-lg text-justify">
          We are dedicated to achieving our vision by delivering unparalleled quality to our customers at the most competitive prices, 
          ensuring their satisfaction and trust in our brand.</p>
        </div>
      </div>
    </motion.div>
  );
};

export default AboutUs;
