import React, { useRef, useEffect, lazy } from 'react';
import kitchenTowelVideo from '../Assets/Kitchentowel.mkv';
import toiletRollBlueVideo from '../Assets/Toiletrollblue.mkv';
import toiletRollGreenVideo from '../Assets/Toiletrollgreen.mkv';
import toiletRollPinkVideo from '../Assets/Toiletrollpink.mkv';
import tissuePacketPurpleVideo from '../Assets/Tissuepacketpurple.mkv';
import tissuePacketBlueVideo from '../Assets/Tissuepacketblue.mkv';
import tissuePacketPinkVideo from '../Assets/Tissuepacketpink.mkv';


const Products = () => {
  const products = [
    { videoSrc: kitchenTowelVideo, productName: 'Kitchen Towel - 120 Sheets - 2 ply' },
    { videoSrc: toiletRollBlueVideo, productName: 'Toilet Roll - 210 Sheets - 2 ply' },
    { videoSrc: toiletRollGreenVideo, productName: 'Toilet Roll - 220 Sheets - 3 ply' },
    { videoSrc: toiletRollPinkVideo, productName: 'Toilet Roll - 280 Sheets - 2 ply' },
    { videoSrc: tissuePacketPurpleVideo, productName: 'Tissue Packet - 100 Sheets - 1 ply' },
    { videoSrc: tissuePacketBlueVideo, productName: 'Tissue Packet - 100 Sheets - 1 ply' },
    { videoSrc: tissuePacketPinkVideo, productName: 'Tissue Packet - 100 Sheets - 1 ply' },
    // Add more objects for additional products
  ];

  return (
    <div className="flex flex-wrap justify-center text-black p-4 md:p-20">
      <h1 className="text-6xl font-bold tracking-tighter mb-8 text-center">Our Products</h1>

      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:grid-rows-2 gap-8 text-lg font-semibold">
        {products.map((product, index) => (
          <ProductItem key={index} videoSrc={product.videoSrc} productName={product.productName} index={index} />
        ))}
      </div>
    </div>
  );
};

const ProductItem = ({ videoSrc, productName, index }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const nextIndex = index + 1;
    const nextVideo = document.querySelector(`#product-video-${nextIndex}`);

    if (nextVideo) {
      videoRef.current.addEventListener('ended', () => {
        nextVideo.load();
      });
    }

    return () => {
      if (nextVideo) {
        videoRef.current.removeEventListener('ended', () => {
          nextVideo.load();
        });
      }
    };
  }, [index]);

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error(`Autoplay failed for ${productName}:`, error);
      });
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const handleTouchStart = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error(`Autoplay failed for ${productName}:`, error);
      });
    }
  };

  const handleTouchEnd = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <div
      className="bg-white rounded-md relative p-18"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <video
        ref={videoRef}
        id={`product-video-${index}`}
        src={videoSrc}
        type="video/mp4"
        controls={false}
        loading = "lazy"
        loop
        muted
        className="h-screen/2 object-contain rounded-md" // Maintain the aspect ratio
      />
      <div className="text-center p-2">{productName}</div>
    </div>
  );
};

export default Products;
