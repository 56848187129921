import React from 'react';
import { Link, Element } from 'react-scroll';
import homeImage from '../src/Assets/Homebg.jpg';
import NavBar from '../src/Components/NavBar';
import Products from './Components/Products';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import Footer from './Components/Footer';
import HomeText from './Components/HomeText';

function App() {
  return (
    <div className="App">
      <NavBar />
      <img
        src={homeImage}
        height={1080}
        alt="Hero Image"
        className="h-screen w-full object-cover md:object-cover sm:object-fill"
        loading='lazy'
      />
      <HomeText />
      

      {/* Wrap sections with Element components */}
      <Element name="products">
        <Products />
      </Element>
      <Element name="aboutUs">
        <AboutUs />
      </Element>
      <Element name="contact">
        <ContactUs />
      </Element>

      <Footer />
    </div>
  );
}

export default App;
