import { motion } from 'framer-motion';

const HomeText = () => {
  return (
    <div className="absolute lg:left-40 left-0 px-5 top-[100px] sm:top-[140px] flex flex-col gap-4 center">
      <motion.div 
        initial={{ opacity: 0, y: -20 }} 
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        className="flex flex-row gap-5 sm:text-6xl text-4xl font-bold"
      >
        <span className="w-full max-w-[692px] text-white">
          Soft on You
        </span>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0, y: -20 }} 
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.25, duration: 0.75 }}
        className="flex flex-row gap-5 sm:text-6xl text-4xl font-bold"
      >
        <span className="w-full max-w-[692px] text-white">
          Tough on Spills
        </span>
      </motion.div>
    </div>
  );
};

export default HomeText;
