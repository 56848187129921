import React from 'react';
import { Link } from 'react-scroll';
import logoImage from '../Assets/logo.png';

const NavBar = () => {
  return (
    <div className="bg-transparent-800 p-4 flex justify-between items-center text-white absolute w-full">
      <div className="flex items-center">
        <img src={logoImage} alt="Logo" className="h-14 w-14 mr-2" />
      </div>
      <div className="flex space-x-4 cursor-pointer ml-auto">
        <Link to="products" spy={true} smooth={true} duration={500}>
          Products
        </Link>
        <Link to="aboutUs" spy={true} smooth={true} duration={750}>
          About Us
        </Link>
        <Link to="contact" spy={true} smooth={true} duration={1000}>
          Contact
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
