import React, { useEffect } from 'react';
import Logo from '../Assets/logo.png';
import LocationIcon from '../Assets/LocationIcon.png';
import MobileIcone from '../Assets/MobileIcon.png';
import EmailIcon from '../Assets/EmailIcon.png';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Footer = () => {
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: -20 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: -20 }}
      animate={controls}
      transition={{ duration: 0.5 }}
      className="flex flex-col pl-4 md:pl-10"
    >
      <div className="mb-4 flex flex-row items-center">
        <img src={Logo} alt="Logo" className="h-12 w-12 md:h-14 md:w-14 mr-2" />
        <h1 className="text-base md:text-lg lg:text-xl xl:text-2xl">Rands Lanka (Pvt) Ltd.</h1>
      </div>
      <div className="mb-4 flex flex-row items-center">
        <img src={LocationIcon} alt="Logo" className="h-5 w-5 md:h-6 md:w-6 mr-2" />
        <h1 className='text-xs md:text-sm lg:text-base xl:text-lg'>No. 232 A - 1, Minuwangoda Road, Kotugoda, Sri Lanka.</h1>
      </div>

      <div className="mb-4 flex flex-row items-center">
        <img src={MobileIcone} alt="Logo" className="h-5 w-5 md:h-6 md:w-6 mr-2" />
        <h1 className='text-xs md:text-sm lg:text-base xl:text-lg'>077 693 1142</h1>
      </div>
      <div className="mb-4 flex flex-row items-center">
        <img src={EmailIcon} alt="Logo" className="h-5 w-5 md:h-6 md:w-6 mr-2" />
        <h1 className='text-xs md:text-sm lg:text-base xl:text-lg'>sales@randslanka.com</h1>
      </div>
    </motion.div>
  );
};

export default Footer;
