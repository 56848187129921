import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import MapComponent from './MapComponent';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    email_id: '',
    mobile: '',
    message: '',
  });

  const [formDataSubscribe, setFormDataSubscribe] = useState({
    email_id: '',
  });

  const [showMap, setShowMap] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  
  const controls = useAnimation();
  const { ref, inView } = useInView();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeSubscribe = (e) => {
    const { name, value } = e.target;
    setFormDataSubscribe((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      setShowMap(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Use your own service ID, template ID, and user ID
    const serviceId = 'service_0aphwau';
    const templateId = 'template_1puhsjh';
    const userId = 'Q0EU8V9eXx0XtuipT';

    emailjs
      .send(serviceId, templateId, formData, userId)
      .then((response) => {
        alert('Email sent');
        console.log('Email sent successfully:', response);
        // You can add any success handling here
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // You can add error handling here
      });
  };

  const handleSubmitSubscribe = (e) => {
    e.preventDefault();

    // Use your own service ID, template ID, and user ID
    const serviceId = 'service_0aphwau';
    const templateId = 'template_gyfpb73';
    const userId = 'Q0EU8V9eXx0XtuipT';

    emailjs
      .send(serviceId, templateId, formDataSubscribe, userId)
      .then((response) => {
        alert('Email sent');
        console.log('Email sent successfully:', response);
        // You can add any success handling here
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // You can add error handling here
      });
  };

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: -20 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      transition={{ duration: 2 }}
      className="flex flex-col-reverse justify-center min-h-screen text-black overflow-none mt-4 md:mt-10 lg:mt-20"
    >
      <div className="p-4 md:p-10 lg:p-20">
        <h1 className="text-6xl font-bold tracking-tighter mb-8 text-center">Contact Us</h1>
        
        <div className="flex flex-col md:flex-row xs:flex-row gap-4 border-2 border-black p-3 md:p-8 lg:p-14 rounded-xl">
          
          {showMap && (
            <div className={isSmallScreen ? "md:w-full mb-8" : "md:w-1/2 xs:w-1/4 mb-8 md:mb-0"}>
              <MapComponent />
            </div>
          )}

          <div className={isSmallScreen ? "md:w-full" : "md:w-1/2"}>
            <h2 className="text-xl md:text-2xl font-bold mb-2">Contact Details</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <p className='text-left'>Name</p>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  className="bg-white rounded-md py-2 px-4 focus:outline-none focus:shadow-outline border border-gray-300 w-full text-black mb-2" required/>
              </div>
              <div className="mb-4">
                <p className='text-left'>Email</p>
                <input
                  type="email"
                  name="email_id"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  className="bg-white rounded-md py-2 px-4 focus:outline-none focus:shadow-outline border border-gray-300 w-full text-black mb-2" required/>
              </div>
              <div className="mb-4">
                <p className='text-left'>Mobile</p>
                <input
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter your mobile number"
                  className="bg-white rounded-md py-2 px-4 focus:outline-none focus:shadow-outline border border-gray-300 w-full text-black mb-2" required/>
              </div>
              <div className="mb-4">
                <p className='text-left'>Message</p>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Enter your message"
                  style={{ resize: "none" }}
                  className="bg-white rounded-md py-2 px-4 focus:outline-none focus:shadow-outline border border-gray-300 w-full text-black mb-2 overflowY-auto" required/>
              </div>
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">Submit</button>
              </form>
          </div>
        </div>
      </div>
      <div className="p-4 md:p-10 lg:p-20 mb-14 mb-16">
        <h1 className="text-4xl font-bold tracking-tighter mt-8 mb-8 text-center">
          Sign up for the latest launches, offers, and news.
        </h1>
        <form onSubmit={handleSubmitSubscribe}>
        <div className="flex flex-col items-center">
          <input
            type="email"
            name="email_id"
            value={formDataSubscribe.email}
            onChange={handleChangeSubscribe}
            placeholder="Enter your email"
            className="bg-white rounded-md py-2 px-4 focus:outline-none focus:shadow-outline border border-gray-300 w-full text-black mb-4 md:w-2/3 lg:w-1/2 xl:w-1/3" required/>
          <button type='submit' className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md w-full md:w-auto">
            Subscribe now
          </button>
          
        </div>
        </form>
      </div>

    </motion.div>
  );
};

export default ContactUs;
