import React from 'react';
import { APIProvider, AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps';

const MapComponent = () => {
  const position = { lat: 7.11891, lng: 79.916552 };
  return (

    <APIProvider apiKey='AIzaSyAHnczL0jBH-MfGnqz1Km8wzD6eBe6aLOw'>
    <Map
        defaultCenter={position}
        mapId={'848a600b0bc6b070'}
        defaultZoom={18}
        gestureHandling={'greedy'}
        >
        <AdvancedMarker position={position} />
        </Map>
  </APIProvider>
  );
};

export default MapComponent;

